import React from "react"
import { Box, Divider, Flex } from "@chakra-ui/react"
import { FC } from "react"

export const MessageDivider: FC = ({ children }) => (
  <Flex alignItems="center">
    <Divider />
    <Box textTransform="uppercase" fontSize="xs" opacity={0.6} px={2}>
      {children}
    </Box>
    <Divider />
  </Flex>
)
