import { Alert, AlertIcon, Button, Collapse, useToast } from "@chakra-ui/react"
import { useMixpanel } from "gatsby-plugin-mixpanel"
import React, { useState } from "react"
import { RiGoogleFill } from "react-icons/ri"
import { getFirebaseApp } from "./FirebaseContext"
import firebase from "firebase/app"
import "firebase/auth"

type MessageVariant = "" | "continue"

export function GoogleLoginButton({
  messageVariant = "",
  onSuccess,
}: {
  messageVariant?: MessageVariant
  onSuccess: () => void
}) {
  const mixpanel = useMixpanel()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")

  async function handleGoogleLogin() {
    setError("")
    setLoading(true)

    try {
      const provider = new firebase.auth.GoogleAuthProvider()
      await getFirebaseApp().auth().signInWithPopup(provider)
      setLoading(false)
      mixpanel.track("Login")
      onSuccess()
    } catch (err) {
      setLoading(false)
      // User cancels login
      if (err.code === "auth/popup-closed-by-user") return
      setError(err.message)
      console.log(err)
    }
  }

  return (
    <>
      <Collapse in={!!error}>
        <Alert status="error">
          <AlertIcon />
          {error}
        </Alert>
      </Collapse>
      <Button
        size="lg"
        leftIcon={<RiGoogleFill />}
        onClick={handleGoogleLogin}
        isLoading={loading}
      >
        {
          {
            "": "Login with Google",
            continue: "Continue with Google",
          }[messageVariant]
        }
      </Button>
    </>
  )
}
