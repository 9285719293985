import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
} from "@chakra-ui/react"
import { navigate } from "gatsby"
import { useMixpanel } from "gatsby-plugin-mixpanel"
import React, { FormEvent, useRef, useState } from "react"
import { getFirebaseApp } from "../../components/auth/FirebaseContext"
import { GoogleLoginButton } from "../../components/auth/GoogleLoginButton"
import { PasswordInput } from "../../components/auth/PasswordInput"
import { FormVStack } from "../../components/Form"
import { FormLayout } from "../../components/layout/FormLayout"
import { MessageDivider } from "../../components/MessageDivider"

function SignupForm() {
  const mixpanel = useMixpanel()
  const emailRef = useRef<HTMLInputElement>(null)
  const passwordRef = useRef<HTMLInputElement>(null)

  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  async function handleSubmit(e: FormEvent) {
    e.preventDefault()
    mixpanel.track("Sign up - attempt")

    if (!emailRef.current || !passwordRef.current) {
      throw new Error("Can't connect React refs to HTML for user input.")
    }

    try {
      setError("")
      setIsLoading(true)
      const userCredential = await getFirebaseApp()
        .auth()
        .createUserWithEmailAndPassword(
          emailRef.current.value,
          passwordRef.current.value
        )
      const user = userCredential.user
      mixpanel.track("Sign up - create user (email, password)")
      if (!user)
        throw new Error(
          "Expected user for sending email verification after signup"
        )
      await user.sendEmailVerification()
      navigate("/account/verify-email")
    } catch (err) {
      emailRef.current.value = ""
      passwordRef.current.value = ""
      setError(err.message)
    }

    setIsLoading(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormVStack>
        {error && (
          <Alert status="error">
            <AlertIcon />
            {error}
          </Alert>
        )}
        <FormControl id="email">
          <FormLabel>Email address</FormLabel>
          <Input required ref={emailRef} size="lg" type="email" autoFocus />
          <FormHelperText>
            Your email won't be shared with anyone.
          </FormHelperText>
        </FormControl>
        <FormControl id="password">
          <FormLabel>Password</FormLabel>
          <PasswordInput
            isRequired
            inputRef={passwordRef}
            size="lg"
            isNewPassword
          />
        </FormControl>
        <Button
          type="submit"
          colorScheme="green"
          size="lg"
          isLoading={isLoading}
        >
          Continue
        </Button>
      </FormVStack>
    </form>
  )
}

export default function Signup() {
  return (
    <FormLayout title="🌱 Create Account">
      <SignupForm />
      <MessageDivider>or</MessageDivider>
      <GoogleLoginButton
        messageVariant="continue"
        onSuccess={() => navigate("/")}
      />
    </FormLayout>
  )
}
